import { FunctionComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { classesBuilder } from '@common/helpers';
import Page from '@components/Page';

import { MESSAGE_NO_MATCH } from './constants';
import type { TNoMatchPageProps } from './types';

import styles from '../AdPreview/styles.module.scss';

const NoMatchPage: FunctionComponent<TNoMatchPageProps> = (props) => {
  const { message = MESSAGE_NO_MATCH } = props;

  return (
    <Page forceTheme>
      <div
        className={classesBuilder(styles.logo, 'logo', isMobileOnly ? styles['logo-mobile'] : styles['logo-desktop'])}
        data-testid={'yoc-logo'}
      >
        YOC
      </div>
      <div
        className="main"
        data-testid="no-match-page"
      >
        <h1>PAGE NOT FOUND</h1>
        <p>{message}</p>
      </div>
    </Page>
  );
};

export default NoMatchPage;

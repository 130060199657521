import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCollection } from '@common/types';

// Retrieves the default category for the 'collectionData'.
// 1. Sets 'defaultCategory' if it is specified in 'collectionData'.
// 2. If 'defaultCategory' is not set, checks if `YOC_MYSTERY_SCROLLER` exists in the collection and returns it.
// 3. If 'YOC_MYSTERY_SCROLLER' is not found, checks for the presence of the category 'YOC_MYSTERY_AD' and returns it.
// 4. If none of the above conditions are met, returns the category of the first item in ads.
export const getDefaultCategory = (collectionData: TCollection) => {
  const defaultCategory =
    collectionData.defaultCategory ??
    collectionData.ads.find((ad) => {
      return ad.category === E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER;
    })?.category ??
    collectionData.ads.find((ad) => {
      return ad.category === E_AD_CATEGORY_NAME.YOC_MYSTERY_AD;
    })?.category ??
    collectionData.ads[0]?.category;

  return defaultCategory;
};

export const getDefaultSlotId = (collectionData: TCollection) => {
  // Use first found slotId from default category. If there is no default category, use first slotId from the list
  const defaultCategory = getDefaultCategory(collectionData);

  const defaultAd = collectionData.ads.find((item) => {
    return Boolean(item.category === defaultCategory);
  });

  return defaultAd?.slotId ?? collectionData.ads[0].slotId;
};

import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_RETAIL: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'fAdu-ere8-LPnT',
    title: 'Gallery + Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Tena_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: '9Ch3-RcgG-RSDx',
    title: 'Cube',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/spar_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '86cT-LhY6-CAzi',
    title: 'Tap + Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Kaufland_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'vLDn-GhC5-AylA',
    title: 'Partner Campaign',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/MMS_Logo_outline.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'TDi1-oUF9-JTXO',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Zabka_logo_2020.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'byf1-Jjn1-0xdV',
    title: 'Swipe + Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/hagebau_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'hkiB-k15L-zfLC',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Nom_Noem_AG_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'R5Bk-zHOo-JZup',
    title: 'Partner Campaign',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/McDonalds_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'eyBZ-YB3K-yMK1',
    title: 'Cube',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Pepco_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '1Nsp-8mLC-GKWp',
    title: 'Wipe away',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Lidl-Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_RETAIL,
    slotId: 'rsvk-ymnL-mP4d',
    title: 'Store Locator',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Takko_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_RETAIL,
    slotId: 'uFP3-JXzJ-mS1R',
    title: 'Feed Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/TCHIBO.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_RETAIL,
    slotId: 'Qc1v-YaR2-zPpk',
    title: 'Feed Gallery',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/MMS_Logo_outline.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'b7yV-AbsQ-3fc9',
    title: 'Partner Campaign',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Xiaomi_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'WxYq-pmRd-E6bZ',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Aliexpress_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'eqZP-qd70-0z1w',
    title: 'Mosaic',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/kazar_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'fNvH-AvQx-D7BB',
    title: 'Drag &Drop',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Sephora_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'xUM4-47ra-aNAr',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Maggi_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'pMur-6N11-fpwz',
    title: 'Static',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Retail/Galeria_Logo_2021.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'zzxh-5wAr-dy3B+Miac-97SI-Raez',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/bipa_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ZOp9-zafC-X8KW',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/Industry/Pharma/Logo/Logo_Bayer.svg',
  },
];

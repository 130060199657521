import { PropsWithChildren, useEffect } from 'react';

import { E_PREVIEW_THEME } from '@common/enums';
import { useThemeStore } from '@common/stores';
import HelmetHead from '@components/Head';

import type { TPageProps } from './types';

const Page = (props: PropsWithChildren<TPageProps>) => {
  const { children, head, forceTheme = false } = props;
  const { theme, isNeutralApplicable } = useThemeStore();

  // Add theme name to the <body> tag
  useEffect(() => {
    let appliedTheme: string | null = forceTheme ? E_PREVIEW_THEME.YOC : null;
    if (theme !== null) {
      appliedTheme = isNeutralApplicable ? theme : E_PREVIEW_THEME.YOC;
    }

    if (typeof appliedTheme === 'string') {
      document.body.classList.add(appliedTheme);
    }

    return () => {
      if (appliedTheme) {
        document.body.classList.remove(appliedTheme);
      }
    };
  }, [theme, isNeutralApplicable, forceTheme]);

  return (
    <>
      <HelmetHead
        {...head}
        forceTheme={forceTheme}
      />
      {children}
    </>
  );
};

export default Page;

export enum E_COUNTRY_CODE {
  AUSTRIA = 'at',
  GERMANY = 'de',
  SWITZERLAND = 'ch',
  POLAND = 'pl',
  FINLAND = 'fi',
}

export enum E_CUSTOM_CODE {
  HQ = 'hq',
  HEALTH = 'health',
  TEST = 'test',
  RETAIL = 'retail',
  YZA = 'yza',
  TRAVEL = 'travel',
  WEBSITE = 'website',
  YUDS = 'yuds',
  SWEDEN = 'se',
}

export enum E_AD_CATEGORY_NAME {
  YOC_MYSTERY_AD = 'YOC Mystery Ad®',
  YOC_MYSTERY_SCROLLER = 'YOC Mystery Scroller®',
  YOC_ZOOM_AD = 'YOC Zoom Ad',
  YOC_BRANDED_TAKEOVER = 'YOC Branded Takeover',
  YOC_UNDERSTITIAL_AD = 'YOC Understitial Ad®',
  YOC_SKIN = 'YOC Skin',
  YOC_DESKTOP_SKIN = 'YOC Desktop Skin', // TODO: check the final list
  YOC_MOBILE_SKIN = 'YOC Mobile Skin', // TODO: check the final list
  YOC_SITEBAR = 'YOC Sitebar',
  YOC_NONE_IN_SITEBAR = 'YOC None In Sitebar',
  YOC_INLINE_VIDEO_AD = 'YOC Inline Video Ad',
  YOC_UNIVERSAL_VIDEO_AD = 'YOC Universal Video Ad',
  YOC_BRANDED_PLAYER = 'YOC Inline Video Ad Branded Player',
  YOC_INSTREAM_AD = 'YOC Instream Video Ad',
  YOC_UNIVERSAL_VIDEO_SOLUTION = 'YOC Universal Video Solution',
  YOC_UNIVERSAL_DISPLAY_SOLUTION = 'YOC Universal Display Solution',
  YOC_PRIME_SCROLLER = 'YOC Prime Scroller',
  YOC_RETAIL = 'YOC Retail Gallery',
}
export type TAdCategoryName = `${E_AD_CATEGORY_NAME}`;

export enum E_PREVIEW_THEME {
  NEUTRAL = 'neutral',
  YOC = 'default',
}
export type TPreviewTheme = `${E_PREVIEW_THEME}`;
export type TTheme = `${E_PREVIEW_THEME}`;

export enum E_PREVIEW_MODE {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}
export type TPreviewMode = `${E_PREVIEW_MODE}`;

export enum E_EFFECT {
  NONE = 'none',
  INLINE_TO_STICKY = 'inlineToSticky',
  UNDERSTITIAL_MINI = 'understitialMini',
  UNDERSTITIAL_MINI_TO_STICKY = 'understitialMiniToSticky',
  STICKY = 'sticky',
  FULLSCREEN_OVERLAY = 'fullscreenOverlay',
  SITEBAR = 'sitebar',
  NONE_IN_SITEBAR = 'noneInSitebar',
  ZOOM = 'zoom',
  ZOOM_TO_STICKY = 'zoomToSticky',
  BRANDED_PLAYER = 'brandedPlayer',
  NONE_IN_STICKY = 'noneInSticky',
  PRIME_SCROLLER_MOBILE = 'primeScrollerMobile',
  PRIME_SCROLLER_DESKTOP = 'primeScrollerDesktop',
}
export type TEffect = `${E_EFFECT}`;

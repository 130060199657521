import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCollection, TCollectionId } from '@common/types';

import { ADS_COLLECTION_AT } from './ads-collection-at';
import { ADS_COLLECTION_CH } from './ads-collection-ch';
import { ADS_COLLECTION_DE } from './ads-collection-de';
import { ADS_COLLECTION_FI } from './ads-collection-fi';
import { ADS_COLLECTION_HEALTH } from './ads-collection-health';
import { ADS_COLLECTION_HQ } from './ads-collection-hq';
import { ADS_COLLECTION_PL } from './ads-collection-pl';
import { ADS_COLLECTION_RETAIL } from './ads-collection-retail';
import { ADS_COLLECTION_SE } from './ads-collection-se';
import { ADS_COLLECTION_TEST } from './ads-collection-test';
import { ADS_COLLECTION_TRAVEL } from './ads-collection-travel';
import { ADS_COLLECTION_WEBSITE } from './ads-collection-website';
import { ADS_COLLECTION_YUDS } from './ads-collection-yuds';
import { ADS_COLLECTION_YZA } from './ads-collection-yza';

export const ADS_COLLECTION: { [key in TCollectionId]: TCollection } = {
  at: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_AT,
  },
  ch: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_CH,
  },
  de: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_DE,
  },
  fi: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_FI,
  },
  hq: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_HQ,
  },
  health: {
    type: 'collection',
    ads: ADS_COLLECTION_HEALTH,
    tag: 'Showroom | Health',
  },
  pl: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_PL,
  },
  retail: {
    type: 'collection',
    ads: ADS_COLLECTION_RETAIL,
    tag: 'Showroom | Retail',
  },
  se: {
    type: 'collection',
    tag: 'Showroom',
    ads: ADS_COLLECTION_SE,
  },
  test: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_TEST,
  },
  yza: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_YZA,
    defaultCategory: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
  },
  travel: {
    type: 'collection',
    ads: ADS_COLLECTION_TRAVEL,
    tag: 'Showroom | Tourism',
    defaultCategory: E_AD_CATEGORY_NAME.YOC_SKIN,
  },
  website: {
    type: 'showroom-collection',
    ads: ADS_COLLECTION_WEBSITE,
  },
  yuds: {
    type: 'collection',
    ads: ADS_COLLECTION_YUDS,
    tag: 'YOC Universal Display Solution',
    defaultCategory: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
  },
};

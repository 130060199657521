import { memo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { type Route } from 'type-route';

import { E_CUSTOM_CODE, E_PREVIEW_MODE, E_PREVIEW_THEME } from '@common/enums';
import { classesBuilder, getAdCategoryBySlotId, isSlotIdValid } from '@common/helpers';
import { useDmexco } from '@common/hooks';
import { themeController, useAdsStore, useThemeStore } from '@common/stores';
import Page from '@components/Page';
import { routes, useRoute } from '@navigation/router';
import { checkNeutralDomain, getPreviewTemplateUrl } from '@pages/AdPreview/helpers';
import View from '@pages/Showroom/View';

import styles from './styles.module.scss';

const AdPreview = () => {
  const route = useRoute() as Route<typeof routes.preview>;
  const theme = useThemeStore((state) => {
    return state.theme;
  });
  const slotIdString = route.params.slotId; // single or multiple slotIds

  const isSingleSlotId = isSlotIdValid(slotIdString);
  checkNeutralDomain();

  const { adsData: adsDataCollection } = useAdsStore();
  const isDMEXCOMode = useDmexco();

  if (isSingleSlotId && adsDataCollection?.[slotIdString]) {
    themeController(adsDataCollection[slotIdString].previewWebsiteLayout);
  }

  // The logic to forward automatically to cb.* once the cf.* URL is opened on a mobile device
  // https://yocmobile.atlassian.net/browse/TSP-7228
  // TODO: should we check theme or isDMEXCO?
  const shouldRedirect = isMobileOnly && adsDataCollection && Object.keys(adsDataCollection)?.length > 0;

  if (shouldRedirect) {
    const template = getPreviewTemplateUrl(
      E_PREVIEW_MODE.MOBILE,
      (adsDataCollection[slotIdString] || adsDataCollection?.[Object.keys(adsDataCollection)[0]]).previewWebsiteLayout
    );
    // redirect for Mobile as soon as possible
    window.location.href = `${template}/${slotIdString}`;
    return null;
  }

  return (
    <Page>
      {isDMEXCOMode && (
        <div className="dmexco-ad-category">
          <button
            className="back-arrow"
            onClick={() => {
              // Temporary solution for dmexco since it runs on /website route
              routes.collection({ collectionId: E_CUSTOM_CODE.WEBSITE, slotId: slotIdString }).push();
            }}
          />
          <p>{getAdCategoryBySlotId(slotIdString)}</p>
        </div>
      )}
      {theme !== E_PREVIEW_THEME.NEUTRAL && (
        <div
          className={classesBuilder(styles.logo, 'logo', styles['logo-desktop'])}
          data-testid="yoc-logo"
        >
          YOC
        </div>
      )}
      <View slotId={slotIdString} />
    </Page>
  );
};

export default memo(AdPreview);

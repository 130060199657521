import { FunctionComponent, memo } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { E_PREVIEW_THEME } from '@common/enums';
import { useDmexco } from '@common/hooks';
import { useThemeStore } from '@common/stores';
import Navigation from '@components/Navigation';

import { useMobileBackNavigation } from './hooks';
import { IHeaderProps } from './types';

import style from './styles.module.scss';

const Header: FunctionComponent<IHeaderProps> = (props) => {
  const { navigation = <Navigation />, tag = 'Showroom' } = props;
  const onBackBtnClick = useMobileBackNavigation();

  const isDMEXCOMode = useDmexco();
  const theme = useThemeStore((state) => {
    return state.theme;
  });

  return (
    <header
      className={style.header}
      data-testid="header"
    >
      {isMobileOnly && !isDMEXCOMode && (
        <button
          className="back-arrow"
          onClick={onBackBtnClick}
        />
      )}

      <div className="logo-tag">
        {theme !== E_PREVIEW_THEME.NEUTRAL && (
          <div
            className={'logo'}
            data-testid="yoc-logo"
          >
            YOC
          </div>
        )}
        <span
          className="tag"
          data-testid="tag"
        >
          {tag}
        </span>
      </div>

      {(theme === E_PREVIEW_THEME.YOC || theme === null) && navigation}
    </header>
  );
};

export default memo(Header);

import { isMobileOnly } from 'react-device-detect';
import { type Route } from 'type-route';

import { classesBuilder } from '@common/helpers';
import Page from '@components/Page';
import { routes, useRoute } from '@navigation/router';

import { getCookieMessage } from './helpers';

import styles from '../AdPreview/styles.module.scss';

const CookiePage = () => {
  const route = useRoute() as Route<typeof routes.cookie>;

  const messages = getCookieMessage(route.params.actionType);

  return (
    <Page forceTheme>
      <div
        className={classesBuilder(styles.logo, 'logo', isMobileOnly ? styles['logo-mobile'] : styles['logo-desktop'])}
        data-testid={'yoc-logo'}
      >
        YOC
      </div>
      <div
        className="main"
        data-testid="main-content"
      >
        {messages.map((message, index) => {
          return <p key={index}>{message}</p>;
        })}
      </div>
    </Page>
  );
};
export default CookiePage;
